import React from 'react';
import './CustomCard.css'; 

const CustomCard = (props) => {
  return (
    <div className="card">
        {props.children}
    </div>
  );
};

export default CustomCard;