import React from 'react';
import './NotFound.css';

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            <h1>404</h1>
            <p>Page Not Found</p>
            <h1 style={{ textAlign: 'center'}}><span style={{ color: '#10155E'}}>Lead</span><span style={{ color: '#C2628E'}}>Point</span></h1>
        </div>
    );
};

export default NotFoundPage;
