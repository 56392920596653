import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IsAuthenticated from '../../components/api/session';
import Sidebar from '../../components/DashBoard/Sidebar';
import { getChangelogs } from '../../components/api/api';
import './DashBoard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [changelogs, setChangelogs] = useState([]);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!(await IsAuthenticated())) {
        navigate('/login', { replace: true });
      }
    };
    const fetchChangelogs = async () => {
      const result = await getChangelogs();
      if (result.success) {
        setChangelogs(result.data);
      }
    }
    fetchChangelogs();
    checkAuthentication();
  }, [navigate]);

  return (
    <div className='dashboard-container'>
      <Sidebar/>
      <div className='dashboard-content'>
        <h2>Lista Zmian</h2>
        <div className='changelog-container'>
        {changelogs.length && changelogs.map((changelog, index) => (
          <div className='changelog' key={index}>
            <h3>Wersja {changelog.version}</h3>
            <p className='changelog-title'>Zmiany:</p>
            {changelog.description.map((change, index) => (
              <p key={index}>{change}</p>
            ))
            }
            <p className='changelog-date'>Wprowadzono: {changelog.date}</p>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
