import React from 'react';
import './Button.css';

const Button = ({ onClick, type, name }) => {

  const buttonClass = `button ${type === 'red' ? 'button--red' : 'button--blue'}`;

  return (
    <button className={buttonClass} onClick={onClick}>{name}</button>
  );
}

export default Button;