// Function to convert from ISO format to HH:MM format
export function convertToHHMM(isoString) {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

// Function to convert from HH:MM format to ISO format
export function convertToISO(hhmmString) {
    return `0001-01-01T${hhmmString}:00Z`;
}

export function getBearerHeader() {
    return { 'Authorization': `Bearer ${localStorage.getItem('leadpoint_bearer')}` };
}