import { getBearerHeader } from "./utils";

const API_BASE_URL = "https://leadpoint-be.talentina.pl/api";
const UnexpectedError = "An unexpected error occurred";

export const addTaskAPI = async (option, query) => {
  try {
    const AUTH_HEADER = getBearerHeader()
    const resp = await fetch(`${API_BASE_URL}/${option}/tasks?keyword=${query}`, {
      method: 'GET',
      headers: AUTH_HEADER,
    });

    if (resp.ok) {
      return { success: true };
    } else {
      return { error: 'Invalid keyword (already exists)' };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
};

export const fetchResultsAPI = async (output, { startDate, endDate, option, query, limit }) => {
  try {
    const AUTH_HEADER = getBearerHeader()
    const resp = await fetch(`${API_BASE_URL}/getJobs?output=${output}`, {
      method: 'POST',
      headers: { ...AUTH_HEADER, 'Content-Type': 'application/json' },
      body: JSON.stringify({ startDate, endDate, site: option, keyword: query, limit }),
    });

    if (resp.ok) {
      if (output === "json") {
        const data = await resp.json();
        return { success: true, data };
      } else {
        const blob = await resp.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `jobs.${output}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return { success: true };
      }
    } else {
      let response = await resp.json();
      if (response.message === "No jobs found") {
        return { error: "No jobs found" };
      }
      return { error: "Error: " + response.message };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
};

export const loginAPI = async (username, password) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: username, password }),
    });

    if (resp.ok) {
      localStorage.clear();
      const data = await resp.json();
      const token = data.access_token;
      localStorage.setItem('leadpoint_bearer', token);
      localStorage.setItem('expiry', data.expiresAt);
      return { success: true };
    } else {
      return { error: 'Invalid email or password' };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
}

export const registerAPI = async (username, password) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: username, password }),
    });
    let data = await resp.json();
    if (resp.ok) {
      return { success: true };
    } else {
      return { error: data.error };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
}

export const delTaskAPI = async (option, query) => {
  try {
    const AUTH_HEADER = getBearerHeader()
    const resp = await fetch(`${API_BASE_URL}/${option}/deleteTask?keyword=${query}`, {
      method: 'DELETE',
      headers: AUTH_HEADER,
    });

    if (resp.ok) {
      return { success: true };
    } else {
      return { error: 'Failed to delete task.' };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
}

export const getQueries = async (option) => {
  try {
    const AUTH_HEADER = getBearerHeader()
    const resp = await fetch(`${API_BASE_URL}/${option}/getQueries`, {
      method: 'GET',
      headers: AUTH_HEADER,
    });

    if (resp.ok) {
      const data = await resp.json();
      return { success: true, data };
    } else {
      return { error: 'Failed to get queries.' };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
}

export const getChangelogs = async () => {
  try {
    const resp = await fetch(`${API_BASE_URL}/changelogs`, {
      method: 'GET',
    });

    if (resp.ok) {
      const data = await resp.json();
      return { success: true, data };
    } else {
      return { error: 'Failed to get changelogs.' };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
}

export const getSettings = async () => {
  try {
    const AUTH_HEADER = getBearerHeader()
    const resp = await fetch(`${API_BASE_URL}/settings`, {
      method: 'GET',
      headers: AUTH_HEADER,
    });
    if (resp.ok) {
      const data = await resp.json();
      return { success: true, data };
    } else {
      return { error: 'Failed to get changelogs.' };
    }
  } catch (error) {
    return { error: error.message || UnexpectedError };
  }
}

export const patchSettings = async (hours) => {
  try {
    const AUTH_HEADER = getBearerHeader()
    let body = JSON.stringify({
      "email": {
          "time": hours
      }
    })  
    const resp = await fetch(`${API_BASE_URL}/settings`, {
      method: 'PATCH',
      headers: { ...AUTH_HEADER, 'Content-Type': 'application/json' },
      body: body,
    });
    if (resp.ok) {
      return { success: true };
    } else {
      return { error: 'Failed to get changelogs.' };
    }
  
  } catch (error) {
    return { error: error.message || UnexpectedError};
  }
}