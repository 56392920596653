import React from 'react';
import './Button.css';

const Button = ({ onClick, type }) => {

  const buttonClass = `button ${type === 'login' ? 'button--login' : 'button--register'}`;

  return (
    <button className={buttonClass} onClick={onClick}>
      {type === 'login' ? 'Login' : 'Register'}
    </button>
  );
}

export default Button;