import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './views/HomePage/HomePage';
import LoginPage from './views/LoginPage/LoginPage';
import RegisterPage from './views/RegisterPage/RegisterPage';
import Dashboard from './views/DashBoard/DashBoard';
import Subpage from './views/DashBoard/DashBoard_Subpage';
import NotFoundPage from './views/NotFound/NotFound';
import Contact from './views/Contact/Contact';
import Settings from './views/Settings/Settings';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard/" element={<Dashboard />} />
        <Route path="/dashboard/:option" element={<Subpage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
