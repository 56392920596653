import React from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <h1><span style={{ color: '#10155E'}}>Lead</span><span style={{ color: '#C2628E'}}>Point</span></h1>
            <ul>
                <Link to="/dashboard">
                    <li>Home</li>
                </Link>

                <Link to="/dashboard/pracuj">
                    <li>Pracuj.pl</li>
                </Link>
                
                <Link to="/dashboard/justjoinit">
                    <li>JustJoin.IT</li>
                </Link>

                <Link to="/settings">
                    <li>Ustawienia</li>
                </Link>

                <Link to="/contact">
                    <li>Kontakt</li>
                </Link>
                
            </ul>
        </div>
    );
};

export default Sidebar;
