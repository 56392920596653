import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IsAuthenticated from '../../components/api/session';

import CustomInput from '../../components/CustomInput';
import AuthButton from '../../components/LoginPage/Button';
import AuthCard from '../../components/LoginPage/Card';

import { registerAPI } from '../../components/api/api';

import './RegisterPage.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();

  const handleRegister = async () => {
    
    const result = await registerAPI(username, password);

    if (result.success) {
      setSuccess("Please login to continue.");
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 3000);

    } else {
      setError(result.error);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  useEffect(() => {
    const authenticateUser = async () => {
      if (await IsAuthenticated()) {
        navigate('/dashboard', { replace: true });
      }
    };
  
    authenticateUser();
  }, [navigate]);
  

  return (
    
    <div className="content">
      <AuthCard>
        <h1 style={{ textAlign: 'center'}}><span style={{ color: '#10155E'}}>Lead</span><span style={{ color: '#C2628E'}}>Point</span></h1>
        <form>
          <label>
            <CustomInput
              label="Email"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <br />
          <label>
            <CustomInput
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </form>
        <div className="button-container">
          <AuthButton onClick={handleRegister} type="register" />
        </div>
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        {success && <p style={{ color: 'green', textAlign: 'center' }}>{success}</p>}
      </AuthCard>
      <div className="graphic"></div>
    </div>
  );
};

export default LoginPage;
