import React from 'react';
import { Link } from 'react-router-dom';

import './HomePage.css';
import CustomCard from '../../components/CustomCard';
import AuthButton from '../../components/LoginPage/Button';


const HomePage = () => {
  return (
    <div className="section--top">
      <CustomCard>
        <h1>Welcome to <span style={{ color: '#10155E'}}>Lead</span><span style={{ color: '#C2628E'}}>Point</span></h1>
        <h3>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ultricies risus eu luctus lobortis. Integer sit amet bibendum sapien. Vivamus vestibulum ullamcorper arcu, a egestas risus tristique vel. Donec tincidunt velit in iaculis scelerisque. Fusce quis porttitor ante, scelerisque condimentum erat. Nam rhoncus porta metus eget auctor. Nullam tincidunt venenatis feugiat. Nam in lorem congue, sagittis massa et, sagittis quam.
        </h3>
        <div className="button-container">
          <Link to="/login">
            <AuthButton type="login" />
          </Link>
          <Link to="/register">
            <AuthButton type="register" />
          </Link>
        </div>
      </CustomCard>
    </div>
  );
};

export default HomePage;
