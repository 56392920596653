const IsAuthenticated = () => {
    let bearer = localStorage.getItem('leadpoint_bearer');
    if (!bearer) {
        console.log('no token');
        return false;
    }

    const date = Math.floor(Date.now() / 1000);
    const expiry = parseFloat(localStorage.getItem('expiry'));
    if (isNaN(expiry) || date > expiry) {
        localStorage.removeItem('leadpoint_bearer');
        localStorage.removeItem('expiry');
        console.log('session expired');
        return false;
    }
    console.log('session valid');
    return true;
};

export default IsAuthenticated;
