import React from 'react';
import styles from './Card.module.css';

const AuthCard = (props) => {
    return (
      <div className={styles.authCard}>
          {props.children}
      </div>
    );
  };
export default AuthCard;