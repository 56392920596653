import "./Contact.css";

const Contact = () => {


    return (
        <div className="section--top">
            <div className="contact--container">
                <div className="contact--info">
                    <h1>Jeśli masz jakiekolwiek pytania lub wątpliwości, prosimy o kontakt z nami pod adresem:</h1>
                    <h3 style={{ textAlign: 'center'}}>info@leadpoint.talentina.pl</h3>
                    <h1 style={{ textAlign: 'center'}}><span style={{ color: '#10155E'}}>Lead</span><span style={{ color: '#C2628E'}}>Point</span></h1>
                </div>
            </div>
        </div>
        

    )
}

export default Contact;