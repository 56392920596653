import React from 'react';
import './Button.css';

const Button = ({ onClick, type, name }) => {

  const buttonClass = `button ${type === 'addtask' ? 'button--addtask' : 'button--export'}`;

  return (
    <button className={buttonClass} onClick={onClick}>
      {name}
    </button>
  );
}

export default Button;