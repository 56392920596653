import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import IsAuthenticated from '../../components/api/session';
import Button from '../../components/DashBoard/Button';
import CustomInput from "../../components/CustomInput";

import { addTaskAPI, fetchResultsAPI, delTaskAPI, getQueries } from "../../components/api/api";
import { validateSubPage } from "../../components/utils/subpage";
import "./DashBoard_Subpage.css";
import Sidebar from "../../components/DashBoard/Sidebar";

const Subpage = () => {
  const [query, setQuery] = useState("");
  const [queryresult, setQueryResult] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [queries, setQueries] = useState([]);
  const { option } = useParams();
  const navigate = useNavigate();


  const addTask = async () => {
    checkAuthentication();
    const result = await addTaskAPI(option, query);

    if (result.success) {
      window.location.reload();
    } else {
      setError(result.error);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const fetchResults = async (output) => {
    checkAuthentication();
    const result = await fetchResultsAPI(output, { startDate, endDate, option, query:queryresult, limit: 0 });

    if (result.success) {
      if (output === "json") {
        setJobs(result.data.jobs);
      }
    } else {
      setError(result.error);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const deleteTask = async (query) => {
    checkAuthentication();
    const result = await delTaskAPI(option, query);

    if (result.success) {
      window.location.reload();
    } else {
      setError(result.error);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };


  const checkAuthentication = async () => {
    if (!(await IsAuthenticated())) {
      navigate('/login', { replace: true });
    }
  };

  useEffect(() => {
    let isvalid = validateSubPage(option);
    if (!isvalid) {
      navigate('/dashboard', { replace: true });
    }

    const getTasks = async () => {
      const result = await getQueries(option);
      if (result.success) {
        if (result.data.message === "No queries found") {
          return;
        }
        setQueries(result.data.queries);
        
      } else {
        setError(result.error);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
    checkAuthentication();
    setQueryResult("");
    setQuery("");
    setStartDate(null);
    setEndDate(null);
    setJobs([]);
    setError(null);
    setQueries([]);
    

    getTasks();
    
  }, [navigate, option]);

  return (
    <div className="sub-container">
      <Sidebar />
      <div className="sub-content">
        <h1 style={{color: "black"}}>Menadżer Zadań</h1>
        <CustomInput label="Keyword" type="text" value={query} onChange={(e) => setQuery(e.target.value)} />
        <Button onClick={addTask} type="addtask" name="Add Task"/>
        <h1 style={{color: "black"}}>Eksportuj wyniki</h1>
        <CustomInput label="Keyword" type="text" value={queryresult} onChange={(e) => setQueryResult(e.target.value)} />
        <CustomInput label="Od" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
        <CustomInput label="Do" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <div className="button-container">
          
          <Button onClick={() => fetchResults("json")} type="export" name="Wyświetl wyniki" />
          <Button onClick={() => fetchResults("csv")} type="export" name="Eksportuj do CSV" />
          <Button onClick={() => fetchResults("xlsx")} type="export" name="Eksportuj do Excela"/> 
        </div>
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}

        <h1 style={{color: "black"}}>Aktywne zadania</h1>
        <div className="tasks-container">
            {queries.map((query, index) => (
              <div className="task" key={index}>
                <Button onClick={() => deleteTask(query.queryText)} type="delete" name={`Usuń "${query.queryText}"`}  />
              </div>
            ))}
            {queries.length === 0 && <p>Brak dodanych zadań.</p>}
        </div>

        <h1 style={{color: "black"}}>Wyniki</h1>
        <div className="results-container">
          {jobs.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Company</th>
                  <th>Date</th>
                  <th>Lokalizacja</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job, index) => (
                  <tr key={index}>
                    <td>
                      <a href={job.link} target="_blank" rel="noopener noreferrer">
                        {job.title}
                      </a>
                    </td>
                    <td>{job.company_name}</td>
                    <td>{job.time}</td>
                    <td>{job.locations}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Brak wyników do wyświetlenia.</p>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default Subpage;
