import './Settings.css'
import Sidebar from '../../components/DashBoard/Sidebar';
import TimeInput from "react-input-time";
import Button from '../../components/Button';
import { useEffect, useState } from 'react';
import { getSettings, patchSettings } from '../../components/api/api';
import IsAuthenticated from '../../components/api/session';
import { useNavigate } from 'react-router-dom';
import { convertToHHMM, convertToISO } from '../../components/api/utils';

const Settings = () => {
    const [hours, setHours] = useState([]);
    const [hourinput, setHourinput] = useState('');
    const navigate = useNavigate();

    const addHour = async () => {
        const { data } = await getSettings();
        const newHours = [...data.settings.email.time, hourinput];
        patchSettings(newHours);
        window.location.reload();
    }

    const deleteHour = async (hour) => {
        const { data } = await getSettings();
        const newHours = data.settings.email.time.filter((h) => h !== hour);
        await patchSettings(newHours); 
        window.location.reload();
    }

    const getHours = async () => {
        const { data } = await getSettings();
        setHours(data.settings.email.time);
    }

    useEffect(() => {
    
        if (!(IsAuthenticated())) {
            navigate('/login', { replace: true });
        }

        getHours();
    }, [navigate]);

    return (
        <div className="settings-container">
            <Sidebar />
            <div className='settings-content'>
                <h1 style={{color: "black"}}>Dodaj nowe godziny do wysłania emailów.</h1>
                <TimeInput
                    className="input-time"
                    onChange={(e) => setHourinput(convertToISO(e.target.value))}
                    input={<input type="time" />}
                    autoFocus={true}
                    required={true}
                />
                <Button type="red" name={"Dodaj"} onClick={addHour} />

                <h1 style={{color: "black"}}>Obecnie dodane godziny.</h1>
                <div className="hours-container">
                    {hours.map((hour, index) => (
                        <div className="hour" key={index}>
                            <Button type="blue" name={`Usuń ${convertToHHMM(hour)} z godzin.`} onClick={() => deleteHour(hour)} />
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}    

export default Settings;